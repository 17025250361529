import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const BlogPostTemplate = props => {
  const post = props.data.mdx
  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next } = props.pageContext

  const image = post.frontmatter.featuredImage?.childImageSharp?.sizes?.src

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        slug={`blog/${post.slug}`}
        image={image}
      />
      <h1 className="post-title">{post.frontmatter.title}</h1>
      <p className="post-info">
        {`${post.frontmatter.date} • ${post.timeToRead} min read`}
      </p>
      <MDXRenderer>{post.body}</MDXRenderer>
      <h4>Read More:</h4>
      <ul className="post-footer-navigation">
        <li>
          {previous && (
            <Link
              className="post-footer-navigation-link"
              to={`/blog${previous.fields.slug}`}
              rel="prev"
            >
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link
              className="post-footer-navigation-link"
              to={`/blog${next.fields.slug}`}
              rel="next"
            >
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>

      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <Link to="/blog">
        <h2 className="post-footer-title">Stefi Rosca</h2>
      </Link>
      <Bio />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }

    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 600) {
              src
            }
          }
        }
      }
      slug
    }
  }
`
